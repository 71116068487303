import React, { FC, useEffect } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';
import BazaarVoiceSchema from '@phx-husky/bazaarvoice-schema';
import { useBazaarVoice } from '@phx-husky/use-bazaarvoice';

import Layout from 'layout/Layout';
import { OG_PAGE_TYPES } from 'common/Seo/constants';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import PageDescription from 'components/PageDescription';
import SecondaryNavigation from 'components/SecondaryNavigation';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';
import { gtmService } from 'utils/gtmService';

import { ProductDetailsPageProps } from './models';

const ProductDetailsPage: FC<ProductDetailsPageProps> = ({
  data: {
    productDetailsPage: {
      seo,
      pageDescription,
      body,
      banner,
      langProps,
      name,
      category,
      showBazaarvoiceStarRating,
      bazaarvoiceSeeReviewLabel,
      sku,
      ean,
    },
    allSitePage: { nodes },
    allSiteSettings,
    allBrandSettings,
    allSharedComponentsSettings,
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const adaptedBody = bodyAdapter(body);
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const secondaryNavigationAnchors = adaptedBody
    .map((item) => item.value.secondaryNavigationAnchor)
    .filter((item) => item);

  const productImage =
    banner?.[0]?.image?.imagePicker?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  const siteUrl = allSiteSettings?.nodes?.[0]?.siteUrl?.replace(/\/$/, '');
  const pageUrl = `${siteUrl}${pagePathname}`;
  const bvImageUrl = `${siteUrl}${productImage}`;

  const productDataForBv = {
    productId: ean || sku,
    productName: banner?.[0]?.title,
    productPageURL: pageUrl,
    brandName: allBrandSettings?.nodes?.[0]?.brandName,
    productImageURL: bvImageUrl,
  };
  const bazaarVoiceScript = allSiteSettings?.nodes?.[0]?.bvScript;
  const bvScriptUrl = `${process.env.GATSBY_BV_SCRIPT_HOST}/${bazaarVoiceScript}/bv.js`;
  if (bazaarVoiceScript) {
    const { trackCatalogUpdate } = useBazaarVoice(bvScriptUrl);

    useEffect(() => {
      trackCatalogUpdate([productDataForBv], langProps.lang);
    }, [productDataForBv]);
  }

  const bvProductId = allSiteSettings?.nodes?.[0]?.isEanProductId && ean ? ean : sku || ean;

  gtmService.enrichShoppingOptions(adaptedBody, {
    name,
    category,
    pathname,
  });

  useEffect(() => {
    const timeoutId = gtmService.emitProductView(adaptedBody);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Layout
      {...{
        seo: { ...seo, seoOGType: seo.seoOGType || OG_PAGE_TYPES.PRODUCT },
        langProps,
        breadcrumbs,
        pagePathname,
        additionalSchema: 'Product',
        schemaImageUrl: productImage,
      }}
    >
      <BazaarVoiceSchema
        productUrl={pageUrl}
        name={banner?.[0]?.title}
        image={bvImageUrl}
        description={banner?.[0]?.description}
        sku={ean || sku || ''}
        brand={allBrandSettings?.nodes?.[0]?.brandName || ''}
      />
      {banner ? (
        <Banner
          {...banner[0]}
          bvProductId={bvProductId}
          showBazaarvoiceStarRating={showBazaarvoiceStarRating}
          bazaarvoiceSeeReviewLabel={bazaarvoiceSeeReviewLabel}
          closeButtonAriaLabel={allSharedComponentsSettings?.nodes?.[0]?.closeButtonAriaLabel}
          mode={BannerMode.product}
        />
      ) : null}
      {secondaryNavigationAnchors ? (
        <SecondaryNavigation anchors={secondaryNavigationAnchors} lang={langProps.lang} />
      ) : null}
      {pageDescription ? <PageDescription {...pageDescription} /> : null}
      {adaptedBody.map((component) => {
        const [key] = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    productDetailsPage(link: { eq: $link }) {
      name
      category
      seo {
        ...SEOStructureFragment
      }
      pageDescription {
        description
        backgroundColor
        isCustomStyleDescription
        sectionTheme
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      banner {
        ...BannerFragment
      }
      bazaarvoiceSeeReviewLabel
      showBazaarvoiceStarRating
      sku
      ean
      body {
        textWithImage {
          ...TextWithImageFragment
        }
        textWithSquaredImage {
          ...TextWithImageFragment
        }
        productsListing {
          ...ListingFragment
        }
        faq {
          ...FaqFragment
        }
        articlePromoListing {
          ...ListingFragment
        }
        textbox {
          ...TextboxFragment
        }
        teaser {
          ...TeaserFragment
        }
        shoppingOptions {
          ...ShoppingOptionsFragment
        }
        ingredients {
          ...IngredientsFragment
        }
        textWithVideo {
          ...TextWithVideoFragment
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
        howToUse {
          ...HowToUseFragment
        }
        bazaarvoiceReviews {
          ...BazaarvoiceReviewsFragment
        }
        articleCategoryListing {
          ...ListingFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
    allSiteSettings {
      nodes {
        bvScript
        isEanProductId
        siteUrl
      }
    }
    allSharedComponentsSettings {
      nodes {
        closeButtonAriaLabel
      }
    }
    allBrandSettings {
      nodes {
        brandName
      }
    }
  }
`;

export default ProductDetailsPage;
